import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import 'moment/locale/es';
import styled from 'styled-components';
import { jsonToArray, formatNumber } from 'helpers/generic';
import { getUser } from 'helpers/user';
import { default as ReportService } from 'services/Report';
import { default as RepostajeService } from 'services/Repostaje';
import { __, getLanguage } from 'helpers/i18n';

const StatisticsStyled = styled.div` 
	padding: 40px;
	width: 100%;

	h1 {
		color: var(--plenoil-orange2);
		font-size: 25px;
		font-weight: 500;
	}

	h2 {
		font-size: 22px;
		font-weight: normal;
	}

	#statistics-wrapper {
		text-transform: uppercase;
		margin-top: 40px;
		text-align: center;

		h3 {
			font-size: 14px;
			font-weight: 500;
			margin-bottom: 30px;
		}

		.euros {
			display: block;
			color: var(--plenoil-orange2);
			font-size: 22px;
			font-weight: 700;
			margin-bottom: 0px;
		}

		.liters {
			font-size: 20px;
			font-weight: 600;
		}
	}
`;

export default function Statistics() {
	let [monthTotal, setMonthTotal] = useState(null);
	let [yearTotal, setYearTotal] = useState(null);
	let [lastRepostajeTotal, setLastRepostajeTotal] = useState(null);

	useEffect(() => {
		const getData = async () => {
			// Search sales 2 month ago
			let sales = await RepostajeService.listSales(
				moment().subtract(2, 'month').startOf('month').format('YYYY-MM-DD'), 
				moment().format('YYYY-MM-DD')
			);
			sales = jsonToArray(sales).sort((a, b) => {
				// Resort because json key is integer and is showing unordered
				let aDate = moment(a.value.date, 'DD/MM/YY').unix();
				let bDate = moment(b.value.date, 'DD/MM/YY').unix();

				if ( aDate < bDate ) return -1;
				if ( aDate > bDate ) return 1;
				return 0;
			});
			setLastRepostajeTotal(sales[Object.keys(sales).length-1]?.value?.amount ?? 0);

			let monthSummary = await RepostajeService.monthSummary(moment().format('YYYY-MM-DD'));
			setMonthTotal(monthSummary?.totalEuros ?? 0);

			let salesTotalByMonths = await ReportService.getSalesTotalByMonths(moment().format('YYYY'));
			setYearTotal(jsonToArray(salesTotalByMonths).reduce((carry, item) => carry+=item.value, 0));
		}
		getData();
	}, []);

	return (
        <StatisticsStyled className="box-plenoil">
            <h2>{__('dashboard.statistics.welcometo')} Plenoil</h2>
            <h1>{ getUser()?.name }</h1>

            <div className="row" id="statistics-wrapper">
                <div className="col-md-4">
                    <h3>{__('dashboard.statistics.lastRefueling')}</h3>
                    <span className="euros">{lastRepostajeTotal ? formatNumber(lastRepostajeTotal, 2, true, true) : '--,--'} €</span>
                    {/* <span className="liters">--,-- L</span> */}
                </div>
                <div className="col-md-4">
                    <h3>{__('dashboard.statistics.summary')} {moment().locale(getLanguage()).format('MMMM')}</h3>
                    <span className="euros">{monthTotal ? formatNumber(monthTotal, 2, true, true) : '--,--'} €</span>
                    {/* <span className="liters">--,-- L</span> */}
                </div>
                <div className="col-md-4">
                    <h3>{__('dashboard.statistics.summaryof')} {moment().format('YYYY')}</h3>
                    <span className="euros">{yearTotal ? formatNumber(yearTotal, 2, true, true) : '--,--'} €</span>
                    {/* <span className="liters">--,-- L</span> */}
                </div>
            </div>
        </StatisticsStyled>
	);
}


