import React from "react";
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    body {
        background: url('/assets/img/fondo-area-clientes.jpg');
        background-size: cover;
    }
`;

const CristalHover = styled.div` 
    background: var(--plenoil-blue);
    opacity: 0.75;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const StyledLayout = styled.div`
	
    width: 100%;
    height: 100%;
    position: relative;    
`;

const Content = styled.div` 
    position: relative;
    max-width: 1280px;
    margin: 0 auto;
`;

export default function NotLoggedLayout(props) {

	return (
        <React.Fragment>
            <GlobalStyle />
            <CristalHover id="cristal-hover"></CristalHover>
            <StyledLayout>
                <Content className="container">{props.children}</Content>
            </StyledLayout>
        </React.Fragment>
	);
}