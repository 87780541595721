import useStore from "store";

export function getLanguages() {
	let langs = {};

	const langFiles = require.context('../langs', false, /\.(js)$/);
	langFiles.keys().map(langFiles).sort(function(a, b) {
		let aO = a.default.langOrder;
		let bO = b.default.langOrder;

		if ( aO < bO ) return -1;
		if ( aO > bO ) return 1;
		return 0;
	}).forEach(el => {
		let langCode = el.default.langCode;
		let langName = el.default.langName;

		langs[langCode] = langName;
	});

	return langs;
}

export function setLanguage(lang) {
	const dispatch = useStore.getState().dispatch;

	dispatch({
		type: 'setLang',
		data: lang
	});
}

export function getLanguage() {
	return useStore.getState().lang;
}

export function __(text, replaces = []) {
	let lang = useStore.getState().lang;

	const languages = getLanguages();
	if ( !languages.hasOwnProperty(lang) ) lang = 'es';

	let literals = null;
	try {
		literals = require("../langs/" + lang).default;
	} catch (e) {
		return text;
	}


	let literal = literals[text] ?? text;
	for(let idx in replaces) {
		let el = replaces[idx];
		literal = literal.replace(':'+idx, el);
	}

	return literal ?? text;
}