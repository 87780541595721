import React, { useEffect, useState, useRef } from 'react';
import 'moment/locale/es';
import styled from 'styled-components';
import { assets, formatNumber, jsonToArray } from 'helpers/generic';
import { default as UserService } from 'services/User';
import moment from 'moment';
import { __ } from 'helpers/i18n';

const StationStyled = styled.div` 
	width: 100%;
	position: relative;
	overflow: hidden;

	@media (max-width: 768px) {
		height: 300px;
	}

	select {
		background: var(--plenoil-blue2);
		outline: 0;
		color: white;

		option {
			background: white;
			color: var(--plenoil-black);
		}
	}

	#no-favorite-station {
		padding: 20px;
		text-align: center;
		color: var(--plenoil-gray2);

		img {
			width: 150px;
		}
	}

	#favorite-station {
		.top {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 55%;
			background: url('/assets/img/fondo-area-clientes.jpg') 0% 60%;
			background-size: cover;
			padding: 20px;

			span {
				display: flex;
				align-items: center;
				background: white;
				border-radius: 20px;
				white-space: nowrap;
				padding: 5px 15px;
				font-size: 13px;
				font-weight: 500;

				i {
					color: var(--plenoil-red);
					margin-right: 10px;
				}
			}

			select {
				padding: 5px 15px;
				width: 80px;
				margin-left: auto;
			}
		}

		.prices {
			position: absolute;
			top: 55%;
			left: 0;
			width: 100%;
			height: 80px;
			margin-top: -40px;
			display: flex;
			justify-content: center;
			align-items: center;

			& > div {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;

				color: white;
				width: 40%;
				height: 100%;
				border-radius: 20px;
				font-weight: 500;

				div.name {
					font-size: 15px;
				}

				div.price {
					font-size: 24px;
				}
			}

			& > .goa {
				background: var(--plenoil-black);
				margin-right: 20px;
			}

			& > .sp95 {
				background: var(--plenoil-green);
			}
		}

		.bottom {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 45%;
			padding-top: 50px;
			text-align: center;

			.name {
				font-weight: 500;
				font-size: 22px;
			}

			.date {
				font-size: 13px;
			}
		}
	}
`;

export default function Station() {
	let selectRef = useRef(null);

	let [forceReload, setForceReload] = useState(null);
	let [userData, setUserData] = useState(null);
	let [stations, setStations] = useState(null);

	useEffect(() => {
		const getData = async () => {
			let user = await UserService.getData();
			setUserData(user);

			let stations = await UserService.stationsList();
			setStations(stations);
		}
		getData();
	}, [forceReload]);

	const setFavoriteStation = async (station_id) => {
		let result = await UserService.setFavoriteStation(station_id);
		if (result.status) {
			selectRef.current.value = '';
			setForceReload(Math.random());
		}
	}

	return (
        <StationStyled className="box-plenoil">
            {userData?.favorite_station &&
				<div id="favorite-station">
					<div className="top">
						<div className="d-flex justify-space-between">
							<span><i className="bi bi-heart-fill"></i> {__('dashboard.station.station')} {__('dashboard.station.favorite')}</span>
							<select className="input-plenoil d-inline-block border-0" onChange={(e) => setFavoriteStation(e.target.value)} ref={selectRef}>
								<option value="">{__('dashboard.station.edit')}</option>
								{jsonToArray(stations)?.map(el => <option value={el.key} key={el.key}>{el.value}</option>)}
							</select>
						</div>
					</div>
					<div className="prices">
						<div className="goa">
							<div className="name">{__('dashboard.station.diesel')}</div>
							<div className="price">{userData?.favorite_station?.oilDiesel ? formatNumber(userData?.favorite_station?.oilDiesel, 3, true, true) : '-,---'} €</div>
						</div>
						<div className="sp95">
							<div className="name">{__('dashboard.station.sp95')}</div>
							<div className="price">{userData?.favorite_station?.oil95 ? formatNumber(userData?.favorite_station?.oil95, 3, true, true) : '-,---'} €</div>
						</div>
					</div>
					<div className="bottom">
						<div className="name">
							{userData?.favorite_station?.name}
						</div>
						<div className="date">
							{__('dashboard.station.todayprice')}: {moment().format('DD-MM-YYYY')}
						</div>
					</div>
				</div>
			}

            {!userData?.favorite_station &&
				<div id="no-favorite-station">
					<div className="mb-2">
						<img src={assets('assets/img/icono-no-favorita.svg')} alt="favorita" />
						<div>{__('dashboard.station.nofavorite')}</div>
					</div>
					<select className="input-plenoil d-inline-block w-50 mt-3 border-0" onChange={(e) => setFavoriteStation(e.target.value)} ref={selectRef}>
						<option value="">{__('dashboard.station.select')}</option>
						{jsonToArray(stations)?.map(el => <option value={el.key} key={el.key}>{el.value}</option>)}
					</select>
				</div>
			}
        </StationStyled>
	);
}


