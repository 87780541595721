import useStore from "store";

export const getUser = () => {
	return useStore.getState().user;
}
 
export function setUser(data, reload = false, reloadWindow = false) {
	useStore.getState().dispatch({
		type: 'setUser',
		data: data,
		forceReload: reload ? Math.random() : null
	});

	if ( reloadWindow ) window.location.reload();
} 