import React, { useRef, useState } from "react";
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import NotLoggedLayout from 'layouts/NotLoggedLayout';
import { assets } from 'helpers/generic';
import Auth from 'services/Auth';
import { default as UserService } from 'services/User';
import CristalLoader from "components/CristalLoader";
import { __ } from 'helpers/i18n';
import { toast } from "react-toastify";

const LoginStyled = styled.div` 
	margin-top: 50px;
	margin-bottom: 50px;

	#right {
		#login-form {
			background: white;
			max-width: 400px;
			margin: 0 auto;
			padding-top: 100px;
			padding-bottom: 100px;

			#logo {
				display: block;
				margin: 0 auto;
				max-width: 150px;
				margin-bottom: 40px;
			}

			.input-wrapper {
				max-width: 300px;
				margin: 0 auto;
				margin-bottom: 20px;
			}

			button {
				margin-top: 20px;
			}

			p {
				font-size: 14px;
				margin-bottom: 25px;
			}

			.title {
				text-align: center;

				h2 {
					color: var(--plenoil-blue);
				}
			}
		}

	}
	
`;

export default function DeleteAccount() {
	const navigate = useNavigate();

	// Refs
	const usernameRef = useRef(null);
	const passwordRef = useRef(null);

	// State
	let [loading, setLoading] = useState(false);

	const deleteAccount = async (e) => {
		e.preventDefault();

		setLoading(true);

		let result = await Auth.login(usernameRef.current.value, passwordRef.current.value);
		if (result) {
			axios.defaults.headers.common['Authorization'] = 'Bearer ' + result.access_token;
			let resultDelete = await UserService.deleteAccount(passwordRef.current.value);
			if ( resultDelete.status ) {
				navigate('/');
			} else {
				toast.error('Ha ocurrido un error');
			}
		}

		setLoading(false);
	};

	// Render
	return (
		<NotLoggedLayout>
			{loading && <CristalLoader />}
			<LoginStyled>
				<div className="row">
					<div id="right" className="col-md-6">
						<div id="login-form" className="box-plenoil">
							<img src={assets('assets/img/Plenoil-logo-2022.svg')} alt="Plenoil Logo" id="logo" />

							<div className="title">
								<h2>Eliminar cuenta</h2>
							</div>

							<div className="input-wrapper">
								<input ref={usernameRef} placeholder={__('login.loginForm.input.user')} type="text" className="input-plenoil" />
							</div>
							<div className="input-wrapper">
								<input ref={passwordRef} placeholder={__('login.loginForm.input.password')} type="password" className="input-plenoil" />
							</div>
							
							<div className="text-center">
								<button onClick={(e) => deleteAccount(e)} className="btn btn-plenoil btn-plenoil-orange">Eliminar</button>
							</div>
						</div>
					</div>
				</div>
			</LoginStyled>
		</NotLoggedLayout>
	);
}