import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { assets, downloadBlob } from 'helpers/generic';
import { default as RepostajeService } from 'services/Repostaje';
import moment from 'moment';
import { __ } from 'helpers/i18n';

const TicketStyled = styled.div` 
	letter-spacing: 0px;
	font-size: 14px;
	font-weight: 400;
	margin: 0 auto;

	.ticketBody {
		color: var(--plenoil-black);
		padding: 30px 30px;
		width: 100%;
		box-shadow: var(--plenoil-shadow);

		.separator {
			overflow: hidden;
			&::before {
				content: '*****************************************************************************************************';
			}
		}

		.left, 
		.right {
			width: 50%;
		}

		.right {
			text-align: right;
		}

		.full {
			width: 100%;
		}

		.first {
			display: flex;

			.stationName {
				font-weight: 500;
				font-size: 20px;
				text-transform: uppercase;
			}

			.companyData {
				text-transform: uppercase;
				margin-top: 30px;
			}
		}

		.second {
			margin-top: 10px;
			padding: 5px 0;

			.header {
				display: flex;

				.right {
					text-align: left;
					font-weight: 500;
				}
			}

			.body {
				display: flex;
				text-align: left;

				.left {
					max-width: 140px;
					font-weight: 500;
				}

				.right {
					text-align: left;
				}
			}
		}

		.third {
			margin-top: 10px;
			display: flex;

			.left {
				max-width: 140px;
				font-weight: 500;
			}

			.right {
				text-align: left;
			}
		}

		.fourth {
			margin-top: 10px;
			display: flex;

			.left {
				width: 50%;
				font-weight: 500;
			}

			.right {
				width: 50%;
				font-weight: bold;
			}
		}

		.last {
			margin-top: 10px;
			font-size: 12px;
			font-weight: 500;


			.realdecreto {
				font-size: 10px;
				margin-bottom: 10px;
			}

			.taxes {
				font-size: 10px;
			}

			.separator {
				width: 100%;
				height: 10px;
				overflow: hidden;
			}
		}

		#btn-download-ticket {
			font-size: 16px;
		}
	}

	#ticket-empty {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: var(--plenoil-gray2);
		font-weight: 700;
		font-size: 18px;
		width: 100%;
		text-transform: uppercase;

		img {
			display: block;
			width: 120px;
			margin-bottom: 30px;
		}
	}
`;

export default function TicketPreview(props) {
	const [data, setData] = useState(null);

	useEffect(() => {
		let getData = async () => {
			let data = await RepostajeService.getSale(props.id);
			setData(data);
		}
		getData();
	}, [props.id])

	const downloadTicket = async () => {
		let result = await RepostajeService.downloadTicket(props.id);
		if ( result ) downloadBlob(result, 'plenoil_ticket_' + data.numero + '.pdf');
	}

	let showRealDecreto = false;
	if (data?.fecha && moment(data.fecha, 'DD/MM/YYYY').isAfter('2022-03-31') && moment(data.fecha, 'DD/MM/YYYY').isBefore('2023-01-01') ) showRealDecreto = true;

	// Render
	return (
		<TicketStyled id="ticket">
			{ data?.numero !== undefined &&
				<div className="ticketBody">
					<div className="section first">
						<div className="left">
							<div className="stationName">
								{data.estacion}
							</div>
							<div className="companyData">
								<div>{data.empresa.nombre}</div>
								<div>cif: {data.empresa.nif}</div>
								<div>fecha: {data.fecha}</div>
								<div>turno: {data.turno}</div>
							</div>
						</div>
						<div className="right">
							<div>ALBARÁN:</div>
							<div>{data.numero}</div>
						</div>
					</div>

					<div className="section second">
						<div className="header">
							<div className="left">
								<b>Datos</b>
							</div>
							<div className="right">
								<b>{data.comentario}</b>
							</div>
						</div>
						<div className="separator">
							&nbsp;
						</div>
						<div className="body">
							<div className="left">
								<div>SURTIDOR:</div>
								<div>PRODUCTO:</div>
								<div>LITROS:</div>
								<div>PRECIO:</div>
								<div>IMPORTE:</div>
							</div>
							<div className="right">
								<div>{data.surtidor}</div>
								<div>{data.producto}</div>
								<div>{data.litros.toFixed(2)}</div>
								<div>{data.precio.toFixed(3)}</div>
								<div>{data.total.toFixed(2)}</div>
							</div>
						</div>
					</div>

					<div className="separator">

					</div>

					<div className="section third">
						<div className="left">
							<div>IVA:</div>
							<div>B. IMP.:</div>
							<div>IMP. IVA:</div>
						</div>
						<div className="right">
							<div>{data.iva_porcentaje.toFixed(2)}%</div>
							<div>{data.base.toFixed(2)}</div>
							<div>{data.iva_cantidad.toFixed(2)}</div>
						</div>
					</div>

					<div className="separator">
					</div>

					<div className="section fourth">
						<div className="left">
							{/*<div>TOTAL COBRADO:</div>
							<div>TOTAL SUMINISTRADO:</div>*/}

							{data.total_descuento !== 0 && <div>TOTAL DESCUENTO:</div>}
							<div>TOTAL RESERVADO:</div>
							<div>TOTAL SUMINISTRADO:</div>
							{/* {data.total_devuelto !== 0 && <div>TOTAL DEVUELTO:</div>} */}

							{ data.cheque_devolucion &&
								<React.Fragment>
									<div>CHEQUE DEVOLUCIÓN:</div>
									<div>IMPORTE CHEQUE:</div>
									<div>FECHA CADUCIDAD:</div>
								</React.Fragment>
							}
						</div>
						<div className="right">
							{/*<div>{self.state.data.total_cobrado.toFixed(2)} EUR</div>
							<div>{self.state.data.total_suministrado.toFixed(2)} EUR</div>*/}
							{data.total_descuento !== 0 && <div>{data.total_descuento.toFixed(2)} EUR</div>}
							<div>{data.total_cobrado.toFixed(2)} EUR</div>
							<div>{(data.total_suministrado + Math.abs(data.total_descuento)).toFixed(2)} EUR</div>
							{/* {data.total_devuelto !== 0 && <div>{data.total_devuelto.toFixed(2)} EUR</div>} */}

							{ data.cheque_devolucion &&
								<React.Fragment>
									<div>{data.cheque_devolucion.codigo}</div>
									<div>{data.cheque_devolucion.importe.toFixed(2)} EUR</div>
									<div>{data.cheque_devolucion.fechaCaducidad}</div>
								</React.Fragment>
							}
						</div>
					</div>

					<div className="section last">
						{ showRealDecreto &&
							<div className="realdecreto">
								DESCUENTO APLICADO DE 20 CÉNTIMOS SEGÚN REAL DECRETO LEY 6/2022 DE 29 DE MARZO DE 2022
							</div>
						}
					
						<div className="paymenttype">
							Forma de Pago: {data.tipo_pago}
						</div>
						<div className="taxes">
							Imp. Esp. incl. (Eu/1000lt): Estatales = 349 Autonómico = 0
						</div>
					</div>
					
					<div className="text-center mt-4">
						<button className="btn-plenoil btn-plenoil-blue2" id="btn-download-ticket" onClick={() => downloadTicket()}>{__('refuelings.ticketpreview.downloadButton')}</button>
					</div>
				</div>
			}

			{data?.numero === undefined &&
				<div id="ticket-empty">
					<img src={assets('assets/img/icono-vista-ticket.svg')} alt="Vista previa del ticket" />
					{__('refuelings.ticketpreview.preview')}
				</div>
			}
		</TicketStyled>
	);
}