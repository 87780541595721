import axios from 'axios';

export default class Register {

	static axiosCancelToken = axios.CancelToken.source();
	
	static getProvinces = () => {
		this.newAxiosCancelToken();

		return axios.get('api/register/provinces', {
			params: {},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static checkExistingVatnumber = (vatnumber) => {
        return axios.get('api/register/checkExistingNif', {
            params: { vatnumber: (""+vatnumber).trim() }
        }).then((response) => {
            return response.data.exists ?? false;
        }).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			return false;
        });
	}

	static registerStep1 = (data) => {
        return axios.post('api/register/step1', data).then((response) => {
            if ( response.data.status === 1 ) {
				return {
					status: true,
					string: response.data.string ?? null
				}
            } else { // Errors
                return {
					status: false, 
					errors: response.data.errors
				};
            }
        }).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			return {status: false};
        });
	}

	static registerStep2 = (data) => {
        return axios.post('api/register/step2', data).then((response) => {
            if ( response.data.status === 1 ) {
                return {
					status: true
				};
            } else {
                return {
					status: false, 
					errors: response.data.errors
				};
            }
        }).catch((error) => {
            if ( axios.isCancel(error) ) return null;
			return {status: false};
        });
	}

	static registerStep3 = (data) => {
        return axios.post('api/register/step3', data).then((response) => {
            if ( response.data.status === 1 ) {
                return {
					status: true
				}
            } else {
                return {
					status: false, 
					errors: response.data.errors
				};
            }
        }).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			return {status: false};
        });
	}

	static registerStepFinal = (data) => {
        return axios.post('api/register/createRegistrationRequest', data).then((response) => {
            if ( response.data.status === 1 ) {
				return {
					status: true,
					string: response.data.string
				};
            } else { // Errors
                return {
					status: false, 
					errors: response.data.errors
				};
            }
        }).catch((error) => {
            if ( axios.isCancel(error) ) return null;
			return {status: false};
        });
	}

	static resendConfirmationEmail = (string) => {
       	return axios.get('api/register/resendConfirmationEmail', {
            params: {
                string: string
            }
        }).then((response) => {
            if ( response.data.status === 1 ) {
                return true;
            } else {
                return false;
            }
        }).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			return false;
        });
    }

	static cancel = () => {
		this.axiosCancelToken.cancel();
	}

	static newAxiosCancelToken = () => {
		this.axiosCancelToken = axios.CancelToken.source();
	}
	
}