import React, { useRef, useState } from "react";
import { Navigate } from "react-router-dom";
import styled from 'styled-components';
import { NavLink } from "react-router-dom";
import NotLoggedLayout from 'layouts/NotLoggedLayout';
import { getUser, setUser } from 'helpers/user';
import { assets, jsonToArray } from 'helpers/generic';
import Auth from 'services/Auth';
import { toast } from "react-toastify";
import CristalLoader from "components/CristalLoader";
import { getLanguages, setLanguage, __ } from 'helpers/i18n';

const LoginStyled = styled.div` 
	margin-top: 50px;
	margin-bottom: 50px;

	#left {
		color: white;
		margin-bottom: 40px;

		#logo-left {
			display: block;
			margin: 0 auto;
			max-width: 100px;
			filter:  brightness(0) invert(1);
			cursor: pointer;
		}

		h2 {
			text-align: center;
			font-size: 15px;
			margin-top: 20px;
		}

		h1 {
			text-align: center;
			font-size: 45px;
			margin-bottom: 40px;
		}

		p {
			display: block;
			margin: 0 auto;
			max-width: 300px;
			margin-bottom: 10px;
			text-align: center;
			font-size: 17px;
		}

		a {
			margin-top: 45px;
		}

		a.privacy-link {
			text-decoration: none;
			font-style: none;
			color: white;
			font-size: 14px;
		}

		@media (max-width: 768px) {
			h1 {
				font-size: 35px;
				margin-bottom: 10px;
			}


			p {
				display: none;
			}

			a {
				margin-top: 20px;
			}
		}
	}

	#right {
		#login-form {
			background: white;
			max-width: 400px;
			margin: 0 auto;
			padding-top: 100px;
			padding-bottom: 100px;

			#logo {
				display: block;
				margin: 0 auto;
				max-width: 150px;
				margin-bottom: 60px;
			}

			.input-wrapper {
				max-width: 300px;
				margin: 0 auto;
				margin-bottom: 20px;
			}

			button {
				margin-top: 20px;
			}

			p {
				font-size: 14px;
				margin-bottom: 25px;
			}

			.recover-link-wrapper {				
				margin-top: 20px;
				text-align: center;

				button {
					font-size: 14px;
					font-weight: 500;
					color: var(--plenoil-blue2);
					text-decoration: none;
					margin-top: 0px;

					.small {
						margin-right: 5px;
					}
				}
			}

			#lang-selector {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 10px;

				select {
					padding: 2px 5px;

					option {
						padding: 0;
						text-align: left;
					}
				}
			}
		}

	}
	
`;

export default function Login() {
	// Refs
	const usernameRef = useRef(null);
	const passwordRef = useRef(null);
	const usernameRecoverRef = useRef(null);

	// State
	let [recoverPass, setRecoverPass] = useState(false);
	let [loading, setLoading] = useState(false);

	// Languages
	let languages = getLanguages();
	languages = jsonToArray(languages);

	const setLang = (lang) => {
		if ( !lang ) return;
		setLanguage(lang);
	}

	const login = async (e) => {
		e.preventDefault();

		setLoading(true);

		let result = await Auth.login(usernameRef.current.value, passwordRef.current.value);
		if (!result) {
			toast.error(__('login.invalidlogin'));
		} else {
			setUser({...result.user, accessToken: result.access_token}, true, true);
		}

		setLoading(false);
	};

	const recover = async (e) => {
		e.preventDefault();

		setLoading(true);

		let result = await Auth.recoverPassword(usernameRecoverRef.current.value);
		if (result === -1) {
			toast.warning(__('login.recoverpasswordthrottle'));
		}
		if (result === false) {
			toast.error(__('login.invalidlogin'));
		}
		if (result === true) {
			toast.success(__('login.recoverpasswordemailsent'));
			setRecoverPass(false);
			usernameRecoverRef.current.value = '';
		}
		
		setLoading(false);
	};

	if ( getUser() ) return <Navigate to="/" />;

	// Render
	return (
		<NotLoggedLayout>
			{loading && <CristalLoader />}
			<LoginStyled>
				<div className="row">
					<div id="left" className="col-md-6">
						<img src={assets('assets/img/Plenoil-logo-2022.svg')} alt="Plenoil Logo" id="logo-left" onClick={() => window.location.href = 'https://plenoil.es'} />
						<h2>{__('login.leftText.title')}</h2>
						<h1>{__('login.leftText.welcometo')} Plenoil</h1>
						<p>
							{__('login.leftText.line1')}<br />
							{__('login.leftText.line2')}<br />
							{__('login.leftText.line3')}
						</p>
						<p>
							{__('login.noaccountask')}
						</p>
						<div className="text-center">
							<NavLink className="btn btn-plenoil btn-plenoil-white" to="/register">{__('login.createaccountbutton')}</NavLink>
						</div>
						<div className="text-center mt-3">
							<a href="https://plenoil.es/politica-de-privacidad-plenoil/" className="privacy-link" target="_blank" rel="noreferrer"><u>{__('login.privacylink')}</u></a>
						</div>
					</div>

					<div id="right" className="col-md-6">
						<div id="login-form" className="box-plenoil">
							<img src={assets('assets/img/Plenoil-logo-2022.svg')} alt="Plenoil Logo" id="logo" />

							{!recoverPass ?
								<React.Fragment>
									<div className="input-wrapper">
										<input ref={usernameRef} placeholder={__('login.loginForm.input.user')} type="text" className="input-plenoil" />
									</div>
									<div className="input-wrapper">
										<input ref={passwordRef} placeholder={__('login.loginForm.input.password')} type="password" className="input-plenoil" />
									</div>

									<div id="lang-selector">
										<select onChange={(e) => setLang(e.target.value)} value="" className="btn btn-plenoil btn-plenoil-blue2">
											<option value="">{__('login.langselector.label')}</option>
											{languages.map(el => 
												<option key={el.key} value={el.key}>{el.value}</option>
											)}
										</select>
									</div>
									
									<div className="text-center">
										<button onClick={(e) => login(e)} className="btn btn-plenoil btn-plenoil-orange">{__('login.loginForm.loginbutton')}</button>
									</div>

									<div className="recover-link-wrapper">
										<button className="btn btn-link" onClick={() => setRecoverPass(true)}>{__('login.loginForm.lostpasswordButton')}</button>
									</div>
								</React.Fragment>
								:
								<React.Fragment>
									<div className="text-center">
										<p>
											{__('login.recoverPasswordForm.text1')}<br />
											{__('login.recoverPasswordForm.text2')}
										</p>
									</div>

									<div className="input-wrapper">
										<input ref={usernameRecoverRef} placeholder={__('login.recoverPasswordForm.usernameInput')} type="text" className="input-plenoil" />
									</div>

									<div className="text-center">
										<button onClick={(e) => recover(e)} className="btn btn-plenoil btn-plenoil-orange">{__('login.recoverPasswordForm.button')}</button>
									</div>

									<div className="recover-link-wrapper">
										<button className="btn btn-link" onClick={() => setRecoverPass(false)}><i className="bi bi-chevron-left small"></i> {__('login.recoverPasswordForm.gobackbutton')}</button>
									</div>
								</React.Fragment>
							}
						</div>
					</div>
				</div>
			</LoginStyled>
		</NotLoggedLayout>
	);
}