import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Routes, Route, Navigate, useLocation, useSearchParams } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { getUser, setUser } from 'helpers/user';
import { setLanguage } from "helpers/i18n";
import { getAjaxUrl, setMaintenanceMode } from 'helpers/config';
import useStore from "store";

import Login from "pages/Login";
import Logout from "pages/Logout";
import Register from "pages/Register";
import DeleteAccount from "pages/DeleteAccount";

import Dashboard from "pages/Dashboard";
import Consumptions from "pages/Consumptions";
import Refuelings from "pages/Refuelings";
import Invoices from "pages/Invoices";
import Account from "pages/Account";

import MaintenanceMode from 'components/MaintenanceMode';

const MainContainer = styled.div`
	height: 100%;
`;

function App() {
	const location = useLocation();

    const forceReload = useStore(state => state.forceReload);
	const lang = useStore(state => state.lang); // Needed to update lang on app on lang change from selector
    const maintenanceMode = useStore(state => state.maintenanceMode);
	
	// Fix for dev lang
	const [searchParams] = useSearchParams();
	let langSP = searchParams.get('lang');
	if ( langSP ) setLanguage(langSP);

	// Axios global
    axios.defaults.baseURL = getAjaxUrl();
	axios.defaults.headers.common['Authorization'] = getUser()?.accessToken ? 'Bearer ' + getUser()?.accessToken : null;
    axios.defaults.headers.common['AppLanguage'] = lang;

    // Axios interceptors
    axios.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        // Check unauthorized error
        if ( error.response && error.response.status === 401 ) {
			if (getUser()?.id) {
				setUser(null, true);
				window.location.reload();
			}
        }

		if ( error.response && error.response.status === 503 ) {
			setMaintenanceMode(error.response.status === 503);
		}

        // Default
        return Promise.reject(error);
    });

	return (
		<MainContainer forceReload={forceReload}>
			{maintenanceMode && <MaintenanceMode />}

			{ (!getUser() && location.pathname !== '/login' && location.pathname !== '/register' && location.pathname !== '/delete-account') &&
				<Navigate to="/login" />
			}

			<Routes>
				<Route path="login" element={<Login />} />
				<Route path="logout" element={<Logout />} />
				<Route path="register" element={<Register />} />
				<Route path="delete-account" element={<DeleteAccount />} />

				{getUser()?.id &&
					<React.Fragment>
						<Route path="/" element={<Dashboard />} />
						<Route path="/consumptions" element={<Consumptions />} />
						<Route path="/refuelings" element={<Refuelings />} />
						<Route path="/invoices" element={<Invoices />} />
						<Route path="/account" element={<Account />} />
					</React.Fragment>
				}
			</Routes>

			<ToastContainer
				position="bottom-right"
				autoClose={2000}
			/>
		</MainContainer>
	);
}

export default App;