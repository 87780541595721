import React from 'react';
import styled from 'styled-components';
import moment from 'moment/min/moment-with-locales';
import { assets, formatNumber, jsonToArray } from 'helpers/generic';
import { __, getLanguage } from 'helpers/i18n';

const BarsReportStyled = styled.div`
	overflow-x: auto;

	& > ::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}

	& > ::-webkit-scrollbar-track {
		background: #f1f1f1;
	}

	& > ::-webkit-scrollbar-thumb {
		background: #888;
	}

	& > ::-webkit-scrollbar-thumb:hover {
		background: #555;
	}

	.bars {
		display: block;
		height: 300px;
		width: auto;
		position: relative;
		overflow-x: auto;
		overflow-y: hidden;
		padding-bottom: 10px;
		margin-bottom: 40px;
		letter-spacing: 0px;
		font-size: 11px;
		margin: 0 auto;

		#empty {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			position: absolute;
			left: 50%;
			top: 40px;
			width: 200px;
			margin-left: -100px;
			color: var(--plenoil-gray2);
			font-weight: 500;
			font-size: 16px;

			img {
				width: 80%;
			}
		}

		.barsInner {
			padding-top: 30px;
			height: 100%;
			width: 100%;
			min-width: 400px;
			display: flex;
			justify-content: space-around;

			@media (max-width: 500px) {
				width: 150%;
			}

			.emptyBars {
				width: 100%;
				height: 100%;
				text-align: center;
			}
		}

		.bar {
			width: 5%;
			height: 100%;
			font-weight: bold;
			display: flex;
			flex-direction: column-reverse;

			@media (min-width: 500px) {
				min-width: 40px;
				margin: 0 8px;
			}

			.price {
				display: flex;
				justify-content: center;
				color: var(--plenoil-black);
				text-transform: uppercase;
				letter-spacing: 0px;
				height: 11px;
				margin-bottom: 3px;
				width: 100%;
				white-space: nowrap;
			}

			.month {
				text-align: center;
				color: var(--plenoil-black);
				text-transform: uppercase;
				height: 11px;
				width: 100%;
			}

			.barDraw {
				background: #6679aa;
				width: 100%;
				margin: 5px 0;
			}
		}
	}
`;

export default function BarsReport(props) {
	let statistics = props.statistics;
	if ( !statistics ) statistics = [];

	// Get bigger price
	let biggestPrice = 0;
	let lowestPrice = 0;
	for(let i in statistics) {
		if ( statistics[i] > biggestPrice ) biggestPrice = statistics[i];
		if ( statistics[i] < lowestPrice ) lowestPrice = statistics[i];
	}

	// Prepare bars
	let bars = [];
	for(let i=0; i<=11; i++) {
		// Calc percent
		let percent = (statistics[i] * 100) / biggestPrice;
		if ( isNaN(percent) ) percent = 0;

		let color = '';
		if ( percent >= 80 ) color = 'orange2';
		if ( percent >= 40 && percent < 80 ) color = 'orange';
		if ( percent < 40 ) color = 'yellow';

		let monthNumber = i+1;
		let tmpMonth = moment('2022-' + (monthNumber < 10 ? '0'+monthNumber : monthNumber ) + '-01').locale(getLanguage()).format('MMMM');

		// Html
		bars.push(
			<div key={i} className={'bar'}>
				<div className="month">{tmpMonth.substr(0, 3)}</div>
				<div className="barDraw" style={{height: percent + '%', backgroundColor: 'var(--plenoil-'+color+')'}}></div>
				<div className="price">{formatNumber(statistics[i] ?? 0, 2, true, true)} €</div>
			</div>
		);
	}

	return (
		<BarsReportStyled>
			<div className="bars">
				{ jsonToArray(statistics).reduce((carry, item) => carry += item.value, 0) === 0 && 
					<div id="empty">
						<img src={assets('assets/img/icono-no-consumo.svg')} alt="empty" />
						{__('consumptions.barsReport.empty')}
					</div>
				}
				<div className="barsInner">
					{ bars }
				</div>
			</div>
		</BarsReportStyled>
	);
}