const lang = {
    'langOrder': 1,
    'langCode': 'es',
    'langName': 'Español',

	/**
	 * layouts/LoggedLayout.js
	 */
	'loggedlayout.top.hello': 'Hola',
	'loggedlayout.top.logout': 'Cerrar sesión',
	'loggedlayout.menu.home': 'Inicio',
	'loggedlayout.menu.consumptions': 'Consumos',
	'loggedlayout.menu.refuelings': 'Repostajes',
	'loggedlayout.menu.invoices': 'Facturas',
	'loggedlayout.menu.account': 'Mis datos',

	/**
	 * pages/Login.js
	 */
	'login.leftText.title': 'ÁREA DE CLIENTES',
	'login.leftText.welcometo': 'Bienvenido a',
	'login.leftText.line1': 'Visualiza todas tus facturas y descárgalas en tu móvil',
	'login.leftText.line2': 'Echa un vistazo rápido a tus consumos por meses o por matrículas',
	'login.leftText.line3': 'Consulta el precio de todas nuestras estaciones',
	'login.langselector.label': 'Seleccionar idioma',
	'login.recoverpasswordthrottle': 'Has intentado solicitar una nueva contraseña demasiado rápido. Espera 1 minuto para intentarlo de nuevo.',
	'login.invalidlogin': 'Datos de acceso inválidos',
	'login.recoverpasswordemailsent': 'Te hemos enviado un email con las instrucciones para recuperar tu contraseña',
	'login.loginForm.input.user': 'NIF/CIF (sin espacios)',
	'login.loginForm.input.password': 'CONTRASEÑA',
	'login.loginForm.loginbutton': 'INICIAR SESIÓN',
	'login.loginForm.lostpasswordButton': 'He olvidado la contraseña',
	'login.recoverPasswordForm.text1' : 'Introduce tu NIF/CIF para recibir un email con las',
	'login.recoverPasswordForm.text2' : 'instrucciones para recuperar tu contraseña',
	'login.recoverPasswordForm.usernameInput': 'NIF/CIF',
	'login.recoverPasswordForm.button': 'SOLICITAR NUEVA CONTRASEÑA',
	'login.recoverPasswordForm.gobackbutton': 'Volver atrás',
	'login.noaccountask': '¿Aún no tienes una cuenta en Plenoil?',
	'login.createaccountbutton': 'Crear cuenta',
	'login.privacylink': 'Términos de uso y Política de privacidad',

    /**
	 * pages/Logout.js
	 */
	'logout.text': 'Cerrando sesión...',

    /**
	 * pages/Register.js
	 */
	'register.title': 'Bienvenido',
	'register.subtitle': 'Completa los siguientes campos para registrarte en Plenoil',
	'register.registerErrorMessage': 'Por favor, corrige los errores antes de continuar',
	'register.genericErrorMessage': 'Ha ocurrido un error, por favor inténtelo de nuevo',
	'register.confirmationMessageResent' : 'Mensaje de confirmación enviado de nuevo',
	'register.title.userData': 'Datos de usuario',
	'register.inputs.name.label': 'Nombre y apellidos / Nombre empresa',
	'register.inputs.name.placeholder': 'Nombre Empresa SL',
	'register.inputs.vatnumber.label': 'CIF/NIF',
	'register.inputs.vatnumber.placeholder': 'XXXXXXXXX',
	'register.inputs.email.label': 'Email',
	'register.inputs.email.placeholder': 'email@ejemplo.com',
	'register.inputs.repeatemail.label': 'Repetir email',
	'register.inputs.repeatemail.placeholder': 'email@ejemplo.com',
	'register.inputs.password.label': 'Contraseña (mínimo 6 digitos)',
	'register.inputs.password.placeholder': '******',
	'register.inputs.phone.label': 'Teléfono',
	'register.inputs.phone.placeholder': 'XXXXXXXXX',
	'register.inputs.province.label': 'Provincia',
	'register.inputs.province.placeholder': 'Tu provincia',
	'register.inputs.city.label': 'Localidad',
	'register.inputs.city.placeholder': 'Tu localidad',
	'register.inputs.address.label': 'Dirección',
	'register.inputs.address.placeholder': 'C/ Ejemplo, 10, 1A',
	'register.inputs.postalcode.label': 'Código postal',
	'register.inputs.postalcode.placeholder': 'XXXXX',
	'register.title.invoices': 'FACTURAS',
	'register.invoices.generate': 'Generar facturas',
	'register.inputs.invoices.porsuministros.label': 'POR SUMINISTRO',
	'register.inputs.invoices.mensuales.label': 'MENSUALES',
	'register.inputs.invoices.porsuministros.text': 'recibirá una factura por cada repostaje',
	'register.inputs.invoices.mensuales.text': 'recibirá una factura mensual con todos los repostajes',
	'register.title.matriculas': 'Matrículas',
	'register.inputs.matriculas.input.placeholder': 'Introduzca matrícula',
	'register.inputs.matriculas.addVehicleButton': 'Añadir vehículo',
	'register.policy.text1': 'He leído y acepto la',
	'register.policy.text2': 'Política de privacidad',
	'register.createAccountButton': 'CREAR CUENTA',
	'register.goBackButton': 'Volver',
	'register.registrationCompleted.title': 'Cuenta creada',
	'register.registrationCompleted.text': 'En breve recibirás un email para verificar tu cuenta',
	'register.registrationCompleted.verifiedButton': 'Ya he verificado',
	'register.registrationCompleted.resendEmailButton': 'Reenviar correo',

	/**
	 * pages/Account/index.js
	 */
	'account.goBackButton': 'Volver',
	'account.userEdit.successMessage': 'Datos recibidos',
	'account.userEdit.errorMessage': 'Error al recibir los datos',
	'account.userEdit.saveButton': 'Guardar',
	'account.userEdit.concentrador.onprogressmessage': 'Actualización en progreso. En breve estará disponible. Puedes seguir navegando.',

	'account.userEdit.title': 'DATOS DE USUARIO',
	'account.userEdit.input.name.label': 'Nombre y apellidos / Nombre empresa',
	'account.userEdit.input.name.placeholder': 'Nombre Empresa SL',
	'account.userEdit.input.phone.label': 'Teléfono',
	'account.userEdit.input.phone.placeholder': 'XXXXXXXXX',
	'account.userEdit.input.email.label': 'Email',
	'account.userEdit.input.email.placeholder': 'email@ejemplo.com',
	'account.userEdit.input.address.label': 'Dirección',
	'account.userEdit.input.address.placeholder': 'C/ Ejemplo, 10, 1A',
	'account.userEdit.input.province.label': 'Provincia',
	'account.userEdit.input.province.placeholder': 'Tu provincia',
	'account.userEdit.input.city.label': 'Ciudad',
	'account.userEdit.input.city.placeholder': 'Tu localidad',
	'account.userEdit.input.postalcode.label': 'Código postal',
	'account.userEdit.input.postalcode.placeholder': 'XXXXXX',
	'account.userEdit.input.password.label': 'Contraseña',
	'account.userEdit.input.password.placeholder': '******',

	'account.invoicingEdit.title': 'FACTURACIÓN',
	'account.invoicingEdit.subtitle': 'Generar facturas',
	'account.invoicingEdit.porsuministro.label': 'POR SUMINISTRO',
	'account.invoicingEdit.porsuministro.text': 'recibirá una factura por cada repostaje.',
	'account.invoicingEdit.mensuales.label': 'MENSUALES',
	'account.invoicingEdit.mensuales.text': 'recibirá una factura mensual con todos los repostajes.',
	'account.invoicingEdit.vehicles.title': 'Vehículos',
	'account.invoicingEdit.vehicles.placeholder': 'Introduzca matrícula',
	'account.invoicingEdit.vehicles.addButton': 'Añadir vehículo',

	/**
	 * pages/Consumptions/index.js
	 */
	'consumptions.title': 'CONSUMOS',
	'consumptions.barsReportFilters.title': 'Resumen anual de consumo',
	'consumptions.barsReportFilters.matriculas.all': 'TODAS',
	'consumptions.mensualReport.title': 'Resumen mensual de consumo',
	'consumptions.mensualReport.total': 'Consumo total mensual',
	'consumptions.mensualReport.interestdata': 'Datos de interes',
	'consumptions.mensualReport.lastRefueling': 'Último repostaje',
	'consumptions.mensualReport.daysBetween': 'Días entre repostajes',
	'consumptions.mensualReport.oils': 'Combustibles',
	'consumptions.mensualReport.totalDiesel': 'Total diesel',
	'consumptions.mensualReport.totalSp95': 'Total SP 95',
	'consumptions.byVehicle.title': 'Consumo por vehículos',


	/**
	 * pages/Consumptions/BarsReport.js
	 */
	'consumptions.barsReport.empty': 'No hay consumos',
	'consumptions.barsReport.totalYear': 'CONSUMO TOTAL ANUAL',

	/**
	 * pages/Consumptions/PieReport.js
	 */
	'consumptions.pieReport.empty': 'No hay consumos',

	/**
	 * pages/Dashboard/index.js
	 */
	'dashboard.link.consumptions': 'CONSUMOS',
	'dashboard.link.refuelings': 'REPOSTAJES',
	'dashboard.link.invoices': 'FACTURAS',
	'dashboard.link.ticketstoinvoices': 'SOLICITAR FACTURA',
	'dashboard.link.account': 'MIS DATOS',

	/**
	 * pages/Dashboard/Station.js
	 */
	'dashboard.station.station': 'GASOLINERA',
	'dashboard.station.favorite': 'FAVORITA',
	'dashboard.station.edit': 'Editar',
	'dashboard.station.select': 'Elegir estación',
	'dashboard.station.diesel': 'DIESEL',
	'dashboard.station.sp95': 'SP95',
	'dashboard.station.todayprice': 'Precio de hoy',
	'dashboard.station.nofavorite': 'Aún no tienes estación favorita',

	/**
	 * pages/Dashboard/Statistics.js
	 */
	'dashboard.statistics.welcometo': 'Bienvenido a',
	'dashboard.statistics.lastRefueling': 'ÚLTIMO REPOSTAJE',
	'dashboard.statistics.summary': 'RESUMEN',
	'dashboard.statistics.summaryof': 'RESUMEN DE',

	/**
	 * pages/Invoices/index.js
	 */
	'invoices.title': 'FACTURAS',
	'invoices.subtitle': 'Resumen de facturas de',
	'invoices.table.date': 'FECHA',
	'invoices.table.number': 'NÚMERO',
	'invoices.table.amount': 'IMPORTE',
	'invoices.table.download': 'DESCARGAR',
	'invoices.table.empty': 'No hay facturas',
	'invoices.selectAllButton': 'Seleccionar todo',
	'invoices.unselectButton': 'Deseleccionar',
	'invoices.downloadButton': 'Descargar facturas',
	'invoices.requestInvoice': 'SOLICITAR FACTURA',

	/**
	 * pages/Refuelings/index.js
	 */
	'refuelings.title': 'Repostajes',
	'refuelings.subtitle': 'Resumen mensual de consumo',
	'refuelings.table.date': 'FECHA',
	'refuelings.table.station': 'ESTACIÓN',
	'refuelings.table.oil': 'COMBUSTIBLE',
	'refuelings.table.liters': 'LITROS',
	'refuelings.table.price': 'PRECIO',
	'refuelings.table.amount': 'IMPORTE',
	'refuelings.table.preview': 'VISTA PREVIA',
	'refuelings.table.empty': 'No hay repostajes',

	/**
	 * pages/Refuelings/TicketPreview.js
	 */
	'refuelings.ticketpreview.preview': 'VISTA PREVIA DEL TICKET',
	'refuelings.ticketpreview.downloadButton': 'Descargar ticket',
}

export default lang;