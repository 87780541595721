import create from "zustand";

// Create reducer
const reducer = (state, action) => {
	if ( action.type === "setUser" ) {
		localStorage.setItem('user', JSON.stringify(action.data));
		return {user: action.data};
	}

	if ( action.type === "setForceReload" ) {
		return {forceReload: Math.random()};
	}

	if ( action.type === "setLang" ) {
		localStorage.setItem('lang', JSON.stringify(action.data));
		return {lang: action.data};
	}

	if ( action.type === "setMaintenanceMode" ) {
		return {maintenanceMode: action.data};
	}

	// Not valid type
	throw new Error();
}

// Default state
const defaultState = {
	forceReload: null,
	maintenanceMode: false,
	debug: !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
	user: (() => {
		let user = '';
		try {
			user = JSON.parse( localStorage.getItem('user') )
		} catch (err) {}
		return user;
	})(),
	lang: (() => {
		let lang = 'es';
		try {
			lang = JSON.parse( localStorage.getItem('lang') )
		} catch (err) {}
		return lang;
	})(),
};

// Create hook
const useStore = create(set => ({
	...defaultState,
	dispatch: (action) => set(state => reducer(state, action)),
}));

export default useStore;