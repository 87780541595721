import React from 'react';
import { useNavigate } from 'react-router-dom';
import 'moment/locale/es';
import styled from 'styled-components';
import LoggedLayout from 'layouts/LoggedLayout';
import { assets } from 'helpers/generic';
import Statistics from './Statistics';
import Station from './Station';
import { __ } from 'helpers/i18n';

const DashboardContainer = styled.div` 
	margin-top: 40px;
	margin-bottom: 60px;
`;

const ButtonsWrapper = styled.div` 
	display: flex;
	width: 100%;
	justify-content: space-between;

	@media (max-width: 1000px) {
		display: block;
	}
`;

const Button = styled.a` 
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	background: var(--plenoil-white);
	border-radius: 30px;
	padding: 40px 30px;
	text-decoration: none;
	color: var(--plenoil-black);
	box-shadow: var(--plenoil-shadow);
	width: 18%;
	font-size: 18px;
	line-height: 100%;
	font-weight: 500;
	cursor: pointer;

	img {
		max-width: 75px;
		max-height: 75px;
		margin-bottom: 20px;
	}

	&:hover {
		color: white;
		background: ${props => props.hoverBackground};

		img {
			filter: brightness(0) invert(1);
		}
	}

	@media (max-width: 1000px) {
		width: 45%;
		display: inline-flex;
		margin: 2.5%;
	}
`;

export default function Dashboard() {
	const navigate = useNavigate();

	return (
		<LoggedLayout showBackground={true}>
			<DashboardContainer>
				<ButtonsWrapper>
					<Button onClick={() => navigate('/consumptions')} hoverBackground="var(--plenoil-orange)">
						<img src={assets('assets/img/icono-consumos.svg')} alt="consumptions" />
						{__('dashboard.link.consumptions')}
					</Button>
					<Button onClick={() => navigate('/refuelings')} hoverBackground="var(--plenoil-green2)">
						<img src={assets('assets/img/icono-repostajes.svg')} alt="refuelings" />
						{__('dashboard.link.refuelings')}
					</Button>
					<Button onClick={() => navigate('/invoices')} hoverBackground="var(--plenoil-blue2)">
						<img src={assets('assets/img/icono-facturas.svg')} alt="invoices" />
						{__('dashboard.link.invoices')}
					</Button>
					{/* <Button onClick={() => window.open("https://tickets.plenoilapp.com/")} hoverBackground="var(--plenoil-blue2)">
						<img src={assets('assets/img/icono-solicitar.svg')} alt="tickets to invoices" />
						{__('dashboard.link.ticketstoinvoices')}
					</Button> */}
					<Button onClick={() => navigate('/account')} hoverBackground="var(--plenoil-yellow2)">
						<img src={assets('assets/img/icono-datos.svg')} alt="account" />
						{__('dashboard.link.account')}
					</Button>
				</ButtonsWrapper>

				<div className="row row-eq-height mt-5">
					<div className="col-md-7 d-flex align-items-md-stretch mb-3">
						<Statistics />
					</div>
					<div className="col-md-5 d-flex align-items-md-stretch mb-3">
						<Station />
					</div>
				</div>
			</DashboardContainer>
		</LoggedLayout>
	);
}


