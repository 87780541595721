import axios from 'axios';

export default class Report {

	static axiosCancelToken = axios.CancelToken.source();
	
	static getSalesTotalByMatricula = (year, month) => {
		this.newAxiosCancelToken();

		return axios.get('api/reports/getSalesTotalByMatricula', {
			params: {
				year: year,
				month: month
			},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}
	
	static monthSummary = (date) => {
		this.newAxiosCancelToken();

		return axios.get('api/reports/monthSummary', {
			params: {
				date: date
			},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}
	
	static getSalesTotalByMonths = (year, matricula) => {
		this.newAxiosCancelToken();

		return axios.get('api/reports/getSalesTotalByMonths', {
			params: {
				year: year,
				matricula: matricula
			},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static listMatriculas = () => {
		return axios.get('api/reports/listMatriculas', {
			params: {},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static cancel = () => {
		this.axiosCancelToken.cancel();
	}

	static newAxiosCancelToken = () => {
		this.axiosCancelToken = axios.CancelToken.source();
	}
	
}