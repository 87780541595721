import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment/min/moment-with-locales';
import LoggedLayout from 'layouts/LoggedLayout';
import { ucfirst, assets, jsonToArray, formatNumber } from 'helpers/generic';
import { default as RepostajeService } from 'services/Repostaje';
import TicketPreview from './TicketPreview';
import { __, getLanguage } from 'helpers/i18n';

const ContainerStyled = styled.div` 
	margin-top: 30px 0;

	table {
		th, td {
			&:nth-child(1) {
				width: 20px;
			}
			&:nth-child(2) {
				width: 100px;
				text-align: center;
				white-space: nowrap;
			}
			&:nth-child(4) {
				width: 100px;
				text-align: center;
				white-space: nowrap;
			}
			&:nth-child(5) {
				width: 80px;
				text-align: center;
			}
			&:nth-child(6) {
				width: 80px;
				text-align: center;
			}
			&:nth-child(7) {
				width: 80px;
				text-align: center;
			}
			&:nth-child(8) {
				width: 100px;
				text-align: center;

				button {
					img {
						width: 20px;
					}
				}
			}
		}
	}

	#empty {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: var(--plenoil-gray2);
		font-weight: 500;
		font-size: 16px;
		margin: 80px 10px;

		img {
			display: block;
			width: 120px;
			margin-bottom: 10px;
		}
	}
`;

export default function Refuelings() {
	let [monthSales, setMonthSales] = useState(undefined);
	let [year, setYear] = useState(moment().format('YYYY'));
	let [month, setMonth] = useState(moment().format('MM'));
	let [previewId, setPreviewId] = useState(null);

	useEffect(() => {
		const getData = async () => {
			let monthSales = await RepostajeService.listMonthSales(year, month);
			setMonthSales(monthSales);
		}
		getData();
	}, [year, month]);

	const previewTicket = (id) => {
		setPreviewId(id);
	}

	return (
		<LoggedLayout>
			<ContainerStyled>
				<div className="row">
					<div className="col-md-12 mb-5">
						<NavLink to="/" id="btn-back"><i className="bi bi-chevron-left"></i> {__('refuelings.title')}</NavLink>
					</div>

					<div className="col-md-8 mb-4">
						<div className="box-plenoil box-plenoil-padded">
							<div className="mb-4">
								<h1 className="box-title">{__('refuelings.subtitle')}:</h1>
								<select className="box-select" value={month ?? ''} onChange={(e) => setMonth(e.target.value)}>
									{(() => {
										let months = [];
										for(let i = 1; i<=12; i++) {
											let tmpMonth = moment('2022-' + (i < 10 ? '0'+i : i ) + '-01').locale(getLanguage()).format('MMMM');
											months.push(<option key={i} value={(i < 10 ? '0'+i : i )}>{ucfirst(tmpMonth)}</option>);
										}
										return months;
									})()}
								</select>
								<select className="box-select" value={year ?? ''} onChange={(e) => setYear(e.target.value)}>
									{(() => {
										let years = [];
										for(let i = 2018; i<=moment().format('YYYY'); i++) {
											years.push(<option key={i} value={i}>{i}</option>);
										}
										return years.reverse();
									})()}
								</select>
							</div>
									
							<div className="table-responsive">
								<table className="box-table">
									<thead>
										<tr>
											<th></th>
											<th>{__('refuelings.table.date')}</th>
											<th>{__('refuelings.table.station')}</th>
											<th>{__('refuelings.table.oil')}</th>
											<th>{__('refuelings.table.liters')}</th>
											<th>{__('refuelings.table.price')}</th>
											<th>{__('refuelings.table.amount')}</th>
											<th>{__('refuelings.table.preview')}</th>
										</tr>
									</thead>
									<tbody>
										{ monthSales && jsonToArray(monthSales).sort((a, b) => {
											// Resort because json key is integer and is showing unordered
											
											let aDate = moment(a.value.date, 'DD/MM/YY').unix();
											let bDate = moment(b.value.date, 'DD/MM/YY').unix();

											if ( aDate < bDate ) return -1;
											if ( aDate > bDate ) return 1;
											return 0;
										}).map((el, idx) => {
											let ticket = el.value;

											return (
												<tr key={idx}>
													<td></td>
													<td>{ticket.date.replaceAll('/', '-')}</td>
													<td>{ticket.station?.name}</td>
													<td>{ticket.product}</td>
													<td>{formatNumber(ticket.liters, 2, true, true)}</td>
													<td>{formatNumber(ticket.price, 3, true, true)}€</td>
													<td>{formatNumber(ticket.amount, 2, true, true)}€</td>
													<td>
														<button className="btn btn-link btn-sm" onClick={() => previewTicket(el.key)}>
															<img src={assets('assets/img/icono-vista-previa.svg')} alt="vista previa" />
														</button>
													</td>
												</tr>
											)
										})}
									</tbody>
								</table>
							</div>
							
							{ (!monthSales || !Object.keys(monthSales).length) &&
								<div id="empty">
									<img src={assets('assets/img/icono-no-repostajes.svg')} alt="empty" />
									{__('refuelings.table.empty')}
								</div>
							}
						</div>
					</div>
					<div className="col-md-4 mb-4">
						<TicketPreview id={previewId} />
					</div>
				</div>
			</ContainerStyled>
		</LoggedLayout>
	);
}


